$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                // change hash
                if ($(this).hasClass("js-changehash")) {
                    window.location.hash = this.hash;
                }

                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    var $heroSlider = $(".js-heroslider"),
        $heroSliderWrapper = $heroSlider.parents(".js-heroslider-wrapper");

    if ($heroSlider.length) {
        $heroSlider.slick({
            infinite: true,
            fade: false,
            dots: true,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 3000,
            waitForAnimate: false,
            prevArrow: $heroSliderWrapper.find(".js-heroslider-nav-prev"),
            nextArrow: $heroSliderWrapper.find(".js-heroslider-nav-next"),
            draggable: false,
            responsive: [],
            rows: 0,
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * logoSlider
    // *
    var $logoSlider = $(".js-logoslider");

    if ($logoSlider.length) {
        $logoSlider.slick({
            infinite: true,
            fade: false,
            dots: false,
            arrows: false,
            autoplay: true,
            speed: 6000,
            autoplaySpeed: 0,
            waitForAnimate: true,
            draggable: false,
            responsive: [],
            rows: 0,
            pauseOnHover: false,
            pauseOnFocus: false,
            variableWidth: true,
            cssEase: 'linear',
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teaserSlider
    // *
    var $teaserSlider = $(".js-teaserslider");

    if ($teaserSlider.length) {
        $teaserSlider.slick({
            infinite: true,
            fade: false,
            dots: true,
            arrows: false,
            autoplay: false,
            autoplaySpeed: 3000,
            waitForAnimate: false,
            draggable: false,
            rows: 0,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
               {
                 breakpoint: 1050,
                 settings: {
                   slidesToShow: 2,
                   slidesToScroll: 1
                 }
               },
               {
                 breakpoint: 700,
                 settings: {
                   slidesToShow: 1,
                   slidesToScroll: 1
                 }
               }
           ]
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navMobile
    // *
    $(".js-navmobile-button").click(function(){
        $(".js-navmobile").toggleClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * productGallery
    // *
    $(".js-product-gallery-item").click(function(){
        $(this).addClass("active").siblings().removeClass("active");
        $(this).find(".productDetails__image").clone().addClass("productDetails__clone").appendTo(".js-product-gallery");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * productSlider
    // *
    var $productSlider = $(".js-product-slider");

    if ($productSlider.length) {

        var $productSliderMain= $(this).find(".js-product-slider-main"),
            $productSliderNav = $(this).find(".js-product-slider-nav");

        $productSliderMain.slick({
            infinite: true,
            fade: false,
            dots: false,
            arrows: false,
            autoplay: false,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: $productSliderNav
        });

        $productSliderNav.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: $productSliderMain,
            focusOnSelect: true,
            centerMode: false,
            dots: false,
            arrows: false,
            infinite: false,
            responsive: [
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });


    }


});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * accordion
// *
$(document).ready(function() {
    $(".js-accordion-button").click(function(){
        var $item = $(this).parents(".js-accordion-item");

        $item.toggleClass("active").find(".js-accordion-content").slideToggle();
        $item.siblings().removeClass("active").find(".js-accordion-content").slideUp();
    });
});

$(window).on('hashchange load', function () {
    var hash = window.location.hash.split('#')[1],
        $accordion = $('.js-accordion-button#' + hash);

    if ($accordion.length) {
        $accordion.trigger("click");
    }
});
